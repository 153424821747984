<template>
  <div class="dashboard-container">
    <!-- 添加或编辑类别 -->
    <el-dialog
      :title="title"
      :visible="showDialog"
      @close="btnCancel"
    >
      <!-- 表单 -->
      <el-form
        ref="perForm"
        :model="formData"
        :rules="rules"
        label-width="120px"
      >
         <el-form-item label="头像" prop="headLogo">
         
          <el-upload
            class="avatar-uploader"
            action="http://up-z2.qiniup.com"
            :show-file-list="false"
            accept="image/jpeg,image/gif,image/png"
            :on-success="handleAvatarSuccess"
            :data="postData"
            :before-upload="beforeAvatarUpload"
          >
            <div v-if="imageUrl" class="avatar">
              <img :src="imageUrl" width="50%" />
            </div>

            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        
        </el-form-item>
        <el-form-item label="用户名" prop="userName">
          <el-input v-model="formData.userName" style="width:50%" />
        </el-form-item>
        <el-form-item label="职位" prop="postInfo">
          <el-input v-model="formData.postInfo" style="width:50%" />
        </el-form-item>
      
        <el-form-item label="电话"  prop="tel">
          <el-input v-model="formData.tel" style="width:50%" />
        </el-form-item>
         <el-form-item label="邮箱"  prop="email">
          <el-input v-model="formData.email" style="width:50%" />
        </el-form-item>
      </el-form>
      <el-row slot="footer" type="flex" justify="center">
        <el-col :span="4">
          <el-button size="small" type="primary" @click="btnOK">
            确定
          </el-button>
          <el-button size="small" @click="btnCancel">取消</el-button>
        </el-col>
      </el-row>
    </el-dialog>

    <!-- -------万恶分割线------- -->

    <div class="app-container" style="margin-bottom: 20px">
      <el-row>
        <el-col :span="12" align="left">
          
           <el-button type="primary" @click="goBack">返回</el-button>
        </el-col>
        <el-col :span="12" align="right">
          <el-button class="el-icon-edit" type="primary" @click="createCompanyCard"
            >添加名片</el-button
          >
        </el-col>
      </el-row>
    </div>

    <!-- 主体内容 -->

    <div class="tab">
      <el-table
        :header-cell-style="{ background: '#F2F6FC', color: '#606266' }"
        :data="list"
        border
        style="width: 100%"
        ref="list"
        class="emp_table"
        v-if="list"
      >


      <el-table-column align="center" prop="headLogo" label="头像" width="200">
          <template slot-scope="scope">
            <div class="permitImg-img">
              <el-image
                style="width: 35%"
                :src="'http://img.c-fol.cn/' + scope.row.headLogo"
                :preview-src-list="[
                  'http://img.c-fol.cn/' + scope.row.headLogo,
                ]"
              />
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="userName"
          label="用户名"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          align="center"
          prop="postInfo"
          label="职位"
          :show-overflow-tooltip="true"
        />
    
           <el-table-column
          align="center"
          prop="tel"
          label="电话"
          :show-overflow-tooltip="true"
        />
          <el-table-column
          align="center"
          prop="email"
          label="邮箱"
          :show-overflow-tooltip="true"
        />

        <!-- <el-table-column
          align="center"
          prop="createTime"
          label="创建时间"
          width="200"
        >
          <template slot-scope="scope">
            {{ scope.row.createTime | getLocalTime }}
          </template>
        </el-table-column> -->
          
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="modifyCompanyCard(scope.row)">
              编辑
            </el-button>
            <el-button type="text" size="small" @click="delCard(scope.row)">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
     <pagination
      v-show="total>0"
      :total="total"
      :page.sync="queryParams.page"
      :limit.sync="queryParams.pageSize"
      @pagination="getCompanyCardList"
    />

    </div>
  </div>
</template>

<script>
import {
  getCompanyCardList, 
  delCard,
  createCompanyCard, 
  modifyCompanyCard, 
  getUploadToken
 
} from "../../request/http";

export default {
  data() {
    return {
       imageUrl: "",
        postData: {
        token: "",
        key: "", //上传的Logo
      },
       title:'',
       showDialog: false,
       list: "", 
       total:0,
       queryParams:{
         page: 1,
         pageSize: 10,
      },
      dialogVisible: false, //显示添加或编辑框
       formData:{
         userName: "", 
         headLogo:'',
         tel:'',
         email:'',
         postInfo: "",
      },
  companyId:'',
   rules: {
         userName: [{ required: true, message: '用户名称不能为空', trigger: 'blur' }],
      postInfo: [{ required: true, message: '职位名称不能为空', trigger: 'blur' }],
       headLogo: [{ required: true, message: '头像不能为空', trigger: 'blur' }],
       tel: [{ required: true, message: '电话不能为空', trigger: 'blur' },
       {
            pattern: /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/,
            message: '请输入正确的手机号码'
          }
       ],
      email: [{ required: true, message: '邮箱不能为空', trigger: 'blur' }]
        
      },

     
    };
  },
 
  created() {
    this.companyId = this.$route.query.companyId
    this.getCompanyCardList();

  },
  mounted() {},
  methods: {
 
// 返回上一级页面
    goBack() {
      this.$router.go(-1);
    },
     // 上传图片方法
     handleAvatarSuccess(res, file) {
      console.log("上传成功", res, file);
      this.imageUrl = "http://img.c-fol.cn/" + res.key;
      this.formData.headLogo= res.key;
     },
     async beforeAvatarUpload(file) {
      // console.log("上传前");
      let { data } = await getUploadToken();
      // console.log(data);
      if (data == "" || data == null) {
        this.message.error("上传图片时获取Token失败！");
        return;
      }
      this.postData.token = data.token;
      this.postData.key = data.fid;
    },
    //获取列表
    async getCompanyCardList() {
      this.queryParams.companyId =this.companyId
      let {data,count} = await getCompanyCardList(this.queryParams);
      this.list = data;
      this.total = count
    },

    // 打开添加的窗口
   createCompanyCard() {
      this.title = '添加会员名片'
      this.formData.companyId = this.companyId
      this.showDialog = true
    },
    // 打开编辑类别的窗口
   modifyCompanyCard(val) {
      // 根据获取id获取详情
      this.title = '编辑会员名片'
         this.imageUrl = "http://img.c-fol.cn/" +val.headLogo
      console.log(val);
      this.formData= {
        cardId:val.cardId,
      headLogo :val.headLogo,
      postInfo :val.postInfo,
      userName :val.userName,
     tel:val.tel,
     email:val.email

      }
      this.showDialog = true
    },
       // 确认
  btnOK() {
      this.$refs.perForm
        .validate()
        .then(() => {
          if (this.formData.cardId) {
            return modifyCompanyCard(this.formData)
          } else {
          return createCompanyCard(this.formData)
          }
         
        })
        .then(() => {
          //  提示消息
          if(this.formData.cardId){
            this.$message.success('编辑成功')
          }
          else {
             this.$message.success('新增成功')
          }
          this.getCompanyCardList()
        
          this.showDialog = false
        })
    },

    //弹窗取消函数
     btnCancel() {
       this.imageUrl=''
      this.formData = {
     
      }
      this.$refs.perForm.resetFields()
      this.showDialog = false
    },
      
    //删除
      async delCard(row) {
      try {
        await this.$confirm('确定要删除该数据吗')
         console.log(row);
       await delCard({
         "cardId":row.cardId
       })
        const totalPage = Math.ceil((this.total -1) / this.queryParams.pageSize)
          this.queryParams.page = this.queryParams.page > totalPage ? totalPage : this.queryParams.page
          this.queryParams.page = this.queryParams.page < 1 ? 1 : this.queryParams.page
        this.getCompanyCardList()
        this.$message.success('删除成功')
      } catch (error) {
        console.log(error)
      }
    },
    }
    }
   
    
  

</script>

<style scoped>
</style>
